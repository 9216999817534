<template>
  <div class="hello">
    <div>
      <img src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/ruian/board.jpg" alt="">
      <img src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/ruian/1.jpg" alt="">
      <img src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/ruian/2.jpg" alt="">
      <img src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/ruian/3.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
img{
  width: 100%;
  vertical-align: middle;
}
</style>
